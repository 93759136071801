<template>
  <div class="vx-row mb-6" style="width: 70%">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Status</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="name"
        label="name"
        :max-height="125"
        :allow-empty="false"
      >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title"
              >{{ props.option.code }} {{ props.option.name }}</span
            >
          </span>
        </template>

        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title"
              >{{ props.option.code }} {{ props.option.name }}</span
            >
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // baseUrl: "/api/oms/v1/setting-user/territory-master",
      options: [],
      selected: null,
    };
  },
  methods: {
    getData() {
      var options = [
        {
          id: "",
          name: "All",
        },
        {
          id: "1",
          name: "Pending",
        },
        {
          id: "2",
          name: "Approved",
        }
      ];
      // options.push({
      //   id: 0,
      //   name: "All",
      //   // code: "All"
      // });
      this.selected = options[0];
      // resp.data.records.forEach(function (item) {
      //   options.push(item);
      // }, options);
      this.options = options;
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      if (v) {
        this.$emit("data", v.id, v.name);
      } else {
        this.$emit("data", v.id, v.name);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>