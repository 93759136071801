<template>
  <vx-card :title="title">
    <div>
      <vs-tabs>
        <vs-tab label="Data">
          <div class="tab-text">
            <customer-category
              @data="setCustomerCategoryId"
            ></customer-category>
            <territory @data="setTerritoryId"></territory>
            <status-approval @data="setStatusApproval"></status-approval>
            <status-download @data="setStatusDownload"></status-download>
            <div class="vx-row mb-6" style="width:70%">
              <div class="vx-col sm:w-1/4 w-full">
                <div class="d-flex vx-row">
                  <span class="vx-col sm:w-1/2 w-full">Created Date</span>
                  <span class="vx-col sm:w-1/2 w-full">
                    <vs-button
                      style="float: right;"
                      color="danger"
                      type="line"
                      icon-pack="feather"
                      icon="icon-refresh-cw"
                      @click="resetDate()"
                    ></vs-button>
                  </span>
                </div>
                
              </div>
              <div class="vx-col sm:w-3/4 w-full d-flex">
                <date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  :clear="true"
                  v-model="validity"
                  :linkedCalendars="true"
                >
                  <template v-slot:input="picker" style="min-width: 350px">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-12">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :customerCategoryId="customerCategoryId"
                  :customerCategoryName="customerCategoryName"
                  :territoryId="territoryId"
                  :territoryCode="territoryCode"
                  :dateFrom="validity.startDate"
                  :dateTo="validity.endDate"
                  :statusApproval="statusApproval"
                  :statusApprovalName="statusApprovalName"
                  :statusDownload="statusDownload"
                  :statusDownloadName="statusDownloadName"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <div class="vx-row mb-12">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <export></export>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export for SAP">
          <div class="tab-text">
            <div class="vx-row mb-12">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <export-sap></export-sap>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable";
import Export from "./Export";
import ExportSap from "./ExportSap";
import CustomerCategory from "./CustomerCategory";
import Territory from "./Territory";
import StatusApproval from "./StatusApproval";
import StatusDownload from "./StatusDownload";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    DataTable,
    CustomerCategory,
    Territory,
    Export,
    ExportSap,
    DateRangePicker,
    StatusApproval,
    StatusDownload,
  },
  data() {
    return {
      title: "Report NOO",
      customerCategoryId: null,
      customerCategoryName: null,
      territoryId: [],
      territoryCode: [],
      statusApproval: "",
      statusDownload: "all",
      statusDownloadName: "All",
      statusApprovalName: "",
      validity: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    setCustomerCategoryId(id, name) {
      this.customerCategoryId = id;
      this.customerCategoryName = name
    },
    setTerritoryId(v) {
      this.territoryId = []
      this.territoryCode = []
      v.forEach(element => {
        this.territoryId.push(element.id)
        this.territoryCode.push(element.code)
      });
      // console.log(v);
      // this.territorryId.push(v.id)
      // this.territorryId = id;
      // this.territoryCode = code
    },
    setStatusApproval(id, name) {
      this.statusApproval = id;
      this.statusApprovalName = name
    },
    setStatusDownload(id, name) {
      this.statusDownload = id;
      this.statusDownloadName = name
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    resetDate(){
      this.validity.startDate = null
      this.validity.endDate = null
    },
  },
  computed: {},
};
</script>
  
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.ps {
  height: 800px;
}
</style>