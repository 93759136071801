<template>
  <div class="vx-row mb-6" style="width:70%">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Territory</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="name"
        label="name"
        :max-height="125"
        :allow-empty="false"
        :custom-label="codeWithName"
        multiple
      >
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseUrl: "/api/oms/v1/setting-user/territory-master",
      options: [],
      selected: []
    };
  },
  methods: {
    codeWithName ({ name, code }) {
      if (name != undefined) {
        return `${code} - ${name}`
      }
      return `${code}`
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            var options = [];
            if (resp.data.records.length == 1) {
              resp.data.records.forEach(function(item) {
                options.push(item);
              }, options);
              this.selected = [options[0]];
            } else {
              options.push({
                id: 0,
                // name: "All",
                code: "All"
              });
              this.selected = [options[0]];
              resp.data.records.forEach(function(item) {
                options.push(item);
              }, options);
            }
            this.options = options;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    }
  },
  watch: {
    selected(v) {
      this.selected = v;
      if (v[v.length-1].id == 0 && v.length > 1) {
        this.selected = [this.options[0]]
      } else if(v[0].id == 0 && v.length > 1){
        this.selected.shift()
      }
      // if (v) {
        // let obj = v.find(o => o.id === 0);
        // if (obj) {

        //   console.log("all");
        // } else {
        //   console.log("tidak all");
        // }
        this.$emit("data", this.selected);
      // }
    }
  },
  mounted() {
    this.getData();
  }
};
</script>